import './terms-named.scss';

import React from 'react';
import { Link, graphql } from 'gatsby';
import { upperFirst } from 'lodash';

import * as Core from 'components/core';
import Layout from 'components/layout/SplitLayout';
import ThumbnailFrameworkGraph from 'components/tree-diagrams/ThumbnailFrameworkGraph';

import { Framework, Term } from 'types';

interface Props {
  data: {
    allFrameworks: {
      frameworks: Framework[];
    };
    terms: {
      terms: Term[];
    };
    allTerms: {
      terms: Term[];
    };
  };

  pageContext: {
    name: string;
    identifiers: Array<number>;
  };
}

export default class TermsNamedPage extends React.Component<Props> {
  get frameworks(): Framework[] {
    return this.props.data.allFrameworks.frameworks;
  }

  findFrameworkByID(id: number): Framework {
    return this.frameworks.find((f) => f.identifier === id) as Framework;
  }

  termsForFrameworkWithID(frameworkID: number): Term[] {
    return this.props.data.allTerms.terms.filter(
      (t) => t.frameworkID === frameworkID
    );
  }

  render() {
    const { terms } = this.props.data.terms;
    const { name } = this.props.pageContext;

    return (
      <Layout>
        <div>
          <h1>
            <strong>&lsquo;{upperFirst(name)}&rsquo;</strong> is used in{' '}
            <strong>{terms.length}</strong> frameworks
          </h1>

          {terms.map((term) => {
            const framework = this.findFrameworkByID(term.frameworkID);
            const terms = this.termsForFrameworkWithID(term.frameworkID);

            return (
              <div key={term.identifier} className="TermsNamedPage-term">
                <Link to={`/frameworks/${term.frameworkID}`}>
                  <div className="TermsNamedPage-term-framework">
                    <div className="TermsNamedPage-term-framework-thumbnail">
                      <ThumbnailFrameworkGraph
                        framework={framework}
                        terms={terms}
                        width={34}
                        height={44}
                      />
                    </div>
                    <div className="TermsNamedPage-term-framework-name">
                      {framework.name}
                    </div>
                  </div>
                </Link>

                <div>
                  <div className="TermsNamedPage-term-definition">
                    <Core.Markdown>{term.definition}</Core.Markdown>
                  </div>
                  <p className="TermsNamedPage-term-link">
                    <Link to={`/terms/${term.identifier}`}>
                      View {term.name} in {framework.name}
                    </Link>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query TermByName($identifiers: [Int!]) {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        name
        color
      }
    }
    terms: allTermsJson(filter: { identifier: { in: $identifiers } }) {
      terms: nodes {
        identifier
        name
        frameworkName
        frameworkID
        parentID
        definition
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkName
        frameworkID
        parentID
      }
    }
  }
`;
